<template>
  <v-sheet class="e-flex e-flex-col">
    <v-slide-x-transition mode="out-in">
      <!-- Queries Table View -->
      <div
        v-if="!showResults"
        ref="container"
        key="queries-view"
        v-resize-observer="setHeight"
        class="smart-search-reports pa-4 px-8"
        :style="containerStyles"
      >
        <div
          class="reports-grid-table__container e-relative e-w-full e-h-full e-flex e-flex-col e-overflow-y-auto e-overflow-x-hidden pr-2"
        >
          <!-- Table Headers -->
          <div
            v-if="queries.length"
            class="reports-grid-table reports-grid-table__headers e-gap-2 e-bg-gray-100 e-p-2 e-font-bold e-text-gray-700 e-sticky e-top-0 e-border-solid e-border-b e-border-gray-200 queries-table-grid"
          >
            <div class="e-flex e-ml-3">Query</div>
            <div class="e-flex e-ml-3">Title</div>
            <div class="e-flex e-ml-3">Description</div>
            <div class="e-flex e-ml-3"># Results</div>
            <div class="e-flex e-ml-3">Author</div>
            <div class="e-flex e-ml-3">Created At</div>
          </div>

          <!-- Table Body -->
          <div class="e-divide-y e-divide-gray-200 reports-grid-table__body">
            <div
              v-for="query in queries"
              :key="query.uuid"
              class="reports-grid-table e-hover:e-bg-gray-50 e-items-center e-p-6 e-border-b e-border-solid e-border-gray-200 overflow-hidden queries-table-grid e-cursor-pointer"
              @click="selectQuery(query)"
            >
              <div class="e-flex">
                <v-fade-transition>
                  <SmartSearchQuery
                    v-if="showQuery"
                    class="report-query"
                    :components="query.components"
                    is-readonly
                    dense
                  />
                </v-fade-transition>
              </div>
              <div class="e-truncate e-font-bold">{{ query.title }}</div>
              <div class="">{{ query.description || "—" }}</div>
              <div class="e-flex e-justify-center">{{ query.totalItems }}</div>
              <div class="e-truncate">{{ query.author }}</div>
              <div class="e-truncate">
                {{ formatTimestamp(query.createdAt) }}
              </div>
            </div>

            <div
              v-if="!queries.length"
              class="e-flex e-w-full e-p-6 e-m-6 e-items-center e-justify-center e-h-full e-text-gray-300"
            >
              No queries to show
            </div>
          </div>
        </div>
      </div>

      <!-- Results Table View -->
      <div
        v-else
        ref="container"
        key="results-view"
        v-resize-observer="setHeight"
        class="smart-search-reports pa-4 px-8"
        :style="containerStyles"
      >
        <div class="e-flex e-items-center">
          <v-btn
            icon
            class="back-btn"
            color="primary"
            @click="showResults = false"
          >
            <v-icon large>fa-arrow-left</v-icon>
          </v-btn>
          <div>
            <h3 class="e-text-xl e-font-bold e-m-0">
              {{ selectedQuery.title }}
            </h3>

            <div>
              {{ selectedQuery.description }}
            </div>
          </div>
        </div>

        <SmartSearchResultsTable
          :key="items.length"
          :is-loading="isExecutingQuery"
          :items="items"
          :items-per-page="limit"
          :page="page"
          :total-items="total"
          @page-change="onPageChange"
        />
      </div>
    </v-slide-x-transition>
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue"
import { useSiteAnalyticsStore } from "@/stores/siteAnalytics"
import { useSmartSearchStore } from "@/stores/smartSearch"
import { PERMISSIONS } from "@/constants/permissions"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import SmartSearchQuery from "@/components/siteAnalytics/SmartSearchQuery.vue"
import SmartSearchResultsTable from "@/components/siteAnalytics/SmartSearchResultsTable.vue"
import { AiApi, SmartSearchQueryReturnType } from "@evercam/api"
import { convertToSmartSearchQuery } from "@/components/siteAnalytics/smart-search-helpers"

export default Vue.extend({
  meta: {
    requiredPermissions: [PERMISSIONS.SITE_ANALYTICS.VIEW],
  },
  name: "SiteAnalyticsSmartSearch",
  components: {
    SmartSearchQuery,
    SmartSearchResultsTable,
  },
  async asyncData({ params }) {
    const cameraExid = params.camera_exid
    const siteAnalyticsStore = useSiteAnalyticsStore()

    if (!cameraExid) {
      return
    } else if (cameraExid !== siteAnalyticsStore.selectedCamera?.exid) {
      await siteAnalyticsStore.selectCamera(cameraExid)
    }

    const smartSearchStore = useSmartSearchStore()
    const queries = smartSearchStore
      .loadQueries()
      .filter((r) => r.cameraExid === cameraExid)

    const breadcrumbStore = useBreadcrumbStore()
    breadcrumbStore.breadcrumbs = [
      {
        name: "Home",
        href: "/",
        icon: "fa-house",
      },
      {
        name: "Site Analytics",
        href: "/site-analytics",
        icon: "fa-chart-simple",
      },
      {
        name: `${siteAnalyticsStore.selectedCamera.name} (${siteAnalyticsStore.selectedCamera.exid})`,
        href: `/site-analytics/${siteAnalyticsStore.selectedCamera.exid}`,
        icon: "fa-camera",
      },
      {
        name: "Reports",
        icon: "fa-file-alt",
      },
    ]

    return {
      queries,
    }
  },
  data() {
    return {
      height: undefined,
      showResults: false,
      selectedQuery: null,
      isExecutingQuery: false,
      items: [],
      page: 1,
      limit: 10,
      total: 0,
      from: 0,
      to: 0,
      returnType: SmartSearchQueryReturnType.FirstLastSeen,
      showQuery: false,
    }
  },
  head() {
    return {
      title: `Reports | ${
        useSiteAnalyticsStore().selectedCamera?.name || "Evercam"
      }`,
    }
  },
  computed: {
    containerStyles() {
      if (!this.height) {
        return
      }

      return {
        height: `${this.height}px`,
        maxHeight: `${this.height}px`,
      }
    },
    siteAnalyticsStore() {
      return useSiteAnalyticsStore()
    },
    smartSearchStore() {
      return useSmartSearchStore()
    },
  },
  watch: {
    showResults: {
      immediate: true,
      handler(showResults) {
        if (!showResults) {
          this.$setTimeout(() => (this.showQuery = true), 600)
        } else {
          this.showQuery = false
        }
      },
    },
  },
  mounted() {
    this.setHeight()
  },
  methods: {
    formatTimestamp(timestamp) {
      if (!timestamp) return "—"

      return this.$moment(timestamp).format("DD/MM/YYYY, hh:mm:ss A")
    },
    setHeight() {
      const containerRect = this.$refs.container?.getBoundingClientRect()
      this.height = window.innerHeight - containerRect.top
    },
    selectQuery(query) {
      this.selectedQuery = query
      this.page = 1
      this.showResults = true
      this.executeQuery()
    },
    onPageChange(newPage) {
      this.page = newPage
      this.executeQuery()
    },
    async executeQuery() {
      try {
        this.items = []
        this.isExecutingQuery = true
        const payload = convertToSmartSearchQuery({
          components: this.selectedQuery.components,
          options: {
            returnType: this.returnType,
          },
        })
        const query = {
          page: this.page,
          pageSize: this.limit,
        }

        const data = await AiApi.siteAnalytics.smartSearch({
          cameraExid: this.siteAnalyticsStore.selectedCameraExid,
          query,
          payload,
        })

        if ("detail" in data) {
          throw new Error(data.detail)
        } else {
          const { page, pageSize, totalCount, results } = data
          this.items = results
          this.total = totalCount
          this.from = (page - 1) * pageSize + 1
          this.to = Math.min(page * pageSize, totalCount)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isExecutingQuery = false
        this.smartSearchStore.returnType = this.returnType
      }
    },
  },
})
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";
@import "~@evercam/shared/styles/mixins";

.queries-table-grid {
  grid-template-columns:
    minmax(0, 2.5fr) minmax(0, 1.75fr) minmax(0, 2fr) minmax(0, 0.5fr)
    minmax(0, 1fr) minmax(0, 1.2fr);
}

.reports-grid-table {
  display: grid;
  z-index: 3;
  padding: 0;
  border: 1px solid #d8dce1;
  background: rgba(241, 243, 246, 0.35);
  border-radius: 0.5rem;
  margin-top: 0.65rem;

  * {
    font-size: 14px;
  }

  &__container {
    &::before {
      content: "";
      position: absolute;
      display: block;
      top: -1px;
      left: 0;
      width: 100%;
      background: white;
      height: 0.5rem;
    }
  }

  &__headers {
    background: rgb(229, 231, 235);
    border: 1px solid #d3d3d3;
    & > div {
      padding: 0.35rem 0;
    }
  }

  &__body {
    &::after {
      content: "";
      position: sticky;
      display: block;
      bottom: -1px;
      left: 0;
      width: 100%;
      background: white;
      height: 0.5rem;
    }
    & > div {
      padding: 0.35rem 1rem;
      &:hover {
        background: aliceblue;
      }
    }
  }

  &:hover {
    background-color: #f9fafb;
    transition: background-color 0.2s ease;
  }
}

.smart-search-reports {
  @include custom-scrollbar(#5d5d5d, #9b9b9b, #333);
}
.back-btn {
  margin-left: -1rem;
  margin-right: 1rem;
  &.v-btn--icon i {
    font-size: 21px !important;
  }
  &.v-btn--icon:before {
    opacity: 0.1;
    &:hover {
      opacity: 0.3;
    }
  }
}
.report-query {
  max-width: 100%;
  overflow: hidden;
}
</style>
